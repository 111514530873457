.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  padding: 80px 160px;
  max-width: 1400px;
  @media (max-width: 768px) {
    padding: 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  .image {
    max-width: 100%;
    height: auto;
    align-self: center;
  }
  h1 {
    color: #670AC1;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 150% */
  }
  h2 {
    margin-top: 30px;
  }
  h3 {
    margin-top: 20px;
  }
  p {
    margin-top: 10px;
  }
  a {
    color: rgb(103, 10, 193);
    text-decoration: underline;
  }
  ul {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    li {
      list-style-type: disc;
      margin-top: 10px;
    }
  }
  ol {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    li {
      list-style-type: decimal;
      margin-top: 10px;
    }
  }
}