.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 24px;
    height: 24px;
  }
  input, textarea {
    flex: 1;
    padding: 24px;
    padding-left: 60px;
    align-items: flex-start;
    gap: var(--Sizing-M, 16px);
    align-self: stretch;
    border-radius: 24px;
    border: 1px solid #CCC;
    background: var(--white-black-white, #FFF);
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  textarea {
    min-height: 140px;
    padding-left: 24px;
  }
  input::placeholder {
    color: #767676;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}