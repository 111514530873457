.container {
  padding: 20px;
  max-width: 400px;
  margin: auto;
  font-family: Arial, sans-serif;

  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .inputGroup {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .input {
    padding: 10px 24px;
  }

  .button {
    padding: 10px 20px;
    background-color: rgb(255, 205, 0);
    color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 15px;
  }

  .result {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
}
