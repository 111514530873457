.container {
  padding: 80px 160px;
  @media (max-width: 768px) {
    padding: 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  h1 {
    color: #670AC1;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 150% */
  }
}