.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  h2 {
    color: #222;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px; /* 133.333% */
  }
  .radio {
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    display: flex;
    flex-direction: column;
    gap: 8px;
    .options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      label {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
    }
  }
}