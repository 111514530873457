.container {
  display: flex;
  padding: 80px 160px;
  @media (max-width: 1024px) {
    padding: 80px 32px;
  }
  @media (max-width: 768px) {
    padding: 80px 16px;
  }
  @media (max-width: 400px) {
    padding: 80px 4px;
  }
  align-items: flex-start;
  gap: 32px;
  .topics {
    display: flex;
    width: 320px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Sizing-M, 16px);
    border-radius: 24px;
    background: var(--white-black-white, #FFF);
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
    @media (max-width: 1024px) {
      display: none;
    }
    h1, h2 {
      color: #444;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
    }
    h2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .active {
      font-weight: 800;
    }
  }
  .postsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1 0 0;
    .posts {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 32px;
      flex: 1 0 0;
      .post {
        align-self: normal;
        min-width: 368px;
        display: flex;
        padding: var(--Sizing-M, 16px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;
        flex: 1 0 0;
        border-radius: 24px;
        background: var(--white-black-white, #FFF);
        box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
        .postHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
          .postTitle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
          h2 {
            color: #222;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 24px; /* 150% */
          }
          h6 {
            color: #888;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
          img {
            width: 56px;
            height: 56px;
            border-radius: 8px;
          }
        }
        p {
          color: #888;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
          // auto truncate
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .tags {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
          span {
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: #F4F4F4;
            color: #444;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 150% */
          }
        }
      }
    }
    .mobileTopics {
      @media (min-width: 1024px) {
        display: none;
      }
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      h2 {
        display: flex;
        padding: 8px var(--Sizing-M, 16px);
        align-items: flex-start;
        gap: var(--Sizing-M, 16px);
        border-radius: 8px;
        border: 1px solid #CCC;
        background: var(--white-black-white, #FFF);
        box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 150% */
      }
      .active {
        border-radius: 8px;
        background: #222;
        box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
        color: var(--white-black-white, #FFF);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 150% */
      }
    }
  }
}